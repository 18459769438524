export default [
    {
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'fa fa-cogs fa-lg',
        permissions: ['admin', 'user']
    },
    {
        title: 'Tools',
        permissions: ["admin", "user"]
    },
    {
        name: 'TN Scraper',
        to: '/tn-scraper',
        icon: 'fa fa-dolly-flatbed fa-lg',
        permissions: ['admin', 'user']
    },
    {
        name: 'Gmail TN Scraper',
        to: '/gmail-tn-scraper',
        icon: 'fa fa-mail-bulk fa-lg',
        permissions: ['admin', 'user']
    },
    {
        name: 'FBA Shipments',
        to: '/fba-shipments-tracking',
        icon: 'fa fa-shipping-fast fa-lg',
        permissions: ['admin', 'user']
    },
    {
        name: 'UPC Generator',
        to: '/upc-generator',
        icon: 'fa fa-barcode fa-lg',
        permissions: ['admin', 'user']
    },
    {
        name: 'Excel Parsers',
        to: '/excel-parsers',
        icon: 'fa fa-file-spreadsheet fa-lg',
        permissions: ['admin', 'user']
    },
    {
        name: 'DC COGS',
        to: '/cogs',
        icon: 'fa fa-coins fa-lg',
        permissions: ['admin', 'user']
    }
]