<template>
    <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0" >
      <template #toggler>
          <CHeaderNavLink>
            <div>
              <span id="username" style="font-size:16px;" class="mt-2 mr-2">{{ userName }}</span>
              <b-img class="border" rounded="circle" :src="userProfile" width="40" height="40"></b-img>
            </div>
          </CHeaderNavLink>
      </template>
      <CDropdownHeader tag="div" class="text-center" color="light">
          <strong>Account</strong>
      </CDropdownHeader>
      <CDropdownItem @click.native="onLogout">
          <b-icon icon="box-arrow-right" class="mr-2"></b-icon> Logout
      </CDropdownItem>
    </CDropdown>
</template>

<script>
import api from '@/services/apiService'
export default {
  computed: {
    userProfile(){
      return 'https://listing-tools.s3.amazonaws.com/default_user.png'
    },
    userName(){
      return this.$store.state.auth ? this.$store.state.auth.email : ''
    }
  },
  methods: {
    onLogout(){
      api.defaults.headers.common['x-auth-token'] = '';
      localStorage.removeItem('auth');
      this.$store.state.auth = null
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  span#username{
    display: none;
  }
}
</style>