<template>
  <CHeader fixed light class="shadow-sm">
    <CToggler
      in-header
      class="ml-1 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-1 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />

    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <h2 class="c-sidebar-brand-full ml-4 mt-2 text-decoration-none">SM Tools</h2>
      <!-- <img
        class="c-sidebar-brand-full ml-5"
        :height="40"
        src="@/assets/logo.png"
        alt="logo-header"
      /> -->
    </CHeaderBrand>

    <!-- Nav Items Component -->
    <CHeaderNav class="ml-auto mr-2">
      <template v-for="(nav, index) in navItems">
        <CHeaderNavItem class="d-md-down-none mx-2" :key="index">
          <CHeaderNavLink v-if="nav.to" :to="nav.to">
            <i :class="`${nav.icon} mr-1`"></i> {{ nav.name || '' }}
          </CHeaderNavLink>
          <CHeaderNavLink v-else :href="nav.href" :target="nav.target">
            <i :class="`${nav.icon} mr-1`"></i> {{ nav.name || '' }}
          </CHeaderNavLink>
        </CHeaderNavItem>
      </template>

      <!-- Account Component -->
      <Account />
    </CHeaderNav>

    <!-- Breadcrumb Component -->
    <CSubheader class="px-1 py-n1">
      <CBreadcrumbRouter class="bg-white border-0 mb-0" style="font-size:14px;"/>
    </CSubheader> 
  </CHeader>
</template>

<script>
import Account from './Account'
export default {
  components: { Account },
  data: () => ({
    navItems: [
      // {
      //   href: 'https://support.2dtransit.com/',
      //   target: '_blank',
      //   name: 'Support',
      //   icon: 'fa fa-info-circle fa-lg'
      // }
    ]
  })
}
</script>
