<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="value => $store.commit('set', ['sidebarShow', value])"
    size="sm"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <h2 class="c-sidebar-brand-full mt-2 text-decoration-none">SM Tools</h2>
      <!-- <img
        class="c-sidebar-brand-full"
        :height="38"
        src="@/assets/logo.png"
        alt="logo-full"
      /> -->
      <h2 class="c-sidebar-brand-minimized mt-2 text-decoration-none">SM</h2>
      <!-- <img
        class="c-sidebar-brand-minimized"
        :height="38"
        src="@/assets/2d-transit-dark-mode-minimized.png"
        alt="logo-minimized"
      /> -->
    </CSidebarBrand>
    <CSidebarNav>
      <template v-for="(item, index) in $options.nav">
        <template v-if="item.title">
          <CSidebarNavTitle
            class="mt-0"
            :key="index"
            v-if="checkPermission(item.permissions)"
            >{{ item.title }}</CSidebarNavTitle
          >
        </template>
        <template v-else>
          <CSidebarNavItem
            :key="index"
            v-if="checkPermission(item.permissions)"
            :name="item.name"
            :to="item.to"
            :fontIcon="item.icon"
            :exact="false"
          ></CSidebarNavItem>
        </template>
      </template>
    </CSidebarNav>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from '../_nav.js'

export default {
  nav,
  computed: {
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
    userData() {
      return { role: 'admin' }
    }
  },
  methods: {
    checkPermission(permissions) {
      if (this.userData) {
        let role = this.userData.role
        return Array.from(permissions).includes(role)
      }
    }
  }
}
</script>
