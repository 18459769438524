<template>
  <CFooter :fixed="false">
    <div>SM Tools &#169; {{ yearNow }}</div>
  </CFooter>
</template>

<script>
export default {
  computed: {
    yearNow() {
      return '2022'
    }
  }
}
</script>
