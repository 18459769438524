<template>
  <div class="main">
    <div v-if="$store.state.pageLoading" class="d-flex justify-content-center align-items-center" style="height:100vh;">
      <div class="text-center">
        <div class="d-flex align-items-center" style="font-size:15px; font-weight: 400px;">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          <span class="ml-2">Loading...</span>
        </div>
      </div>
    </div>

    <div v-else class="c-app">
      <Sidebar />
      <CWrapper>
        <Navbar />
        <div class="c-body">
          <main>
            <div class="p-3">
              <router-view></router-view>
            </div>
          </main>
        </div>
        <Footer />
      </CWrapper>
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Sidebar from './components/Sidebar.vue'
import Footer from './components/Footer'

import api from '@/services/apiService'
export default {
  components: { Navbar, Sidebar, Footer },
  async beforeCreate() {
      this.$store.state.pageLoading = true
      try {
        let authResponse = await api.get(`/api/auth`);
        this.$store.state.auth = authResponse.data
      } catch (error) {
        this.$store.state.auth = null

        localStorage.removeItem('auth')
        this.$router.push('/login')
      }finally{
        this.$store.state.pageLoading = false
      }
  },
}
</script>